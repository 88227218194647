<template>
  <v-row id="client-form-page">
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-form lazy-validation @submit.prevent="saveClient">
            <VechicleSurcharge :model="model">
              <template v-slot>
                <v-col cols="12" lg="4" xl="4" md="4">
                  <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Client Name</v-subheader>
                  <v-text-field v-model="model.name" :error-messages="$helpers.errorMsg('name', $v.model.name, 'Client Name')"
                                placeholder="Client Name" outlined ref="name" hide-details="auto"
                                @input="$v.model.name.$touch()" @blur="$v.model.name.$touch()" required/>
                </v-col>
              </template>
            </VechicleSurcharge>
            <ProductTable :products="model.products || []" @productUpdate="setProduct"/>
            <AddPart @partsUpdate="model.parts = $event" :parts="partsList" :lists="model && model.parts ? model.parts: []"/>
            <DentTable :modelPrices="model.prices.dent" @modelUpdate="model.prices.dent[$event.key][$event.field] = $event.value"/>
            <ScratchTable :modelPrices="model.prices.scratch || {}" @modelUpdate="model.prices.scratch[$event.key][$event.field] = $event.value"/>
            <div class="d-flex justify-end mt-3">
              <v-btn type="submit" color="primary" class="mr-4">
                Submit
              </v-btn>
              <v-btn type="button" outlined color="primary" class="mr-4" @click.native="$router.push('/clients')">
                Cancel
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {mapGetters, mapActions} from 'vuex';
import _ from 'lodash';

function modelInit(data = {}) {
  const get = (key, defaultValue = null) => _.get(data, key) || defaultValue;

  return {
    id: get('id', null),
    name: get('name'),
    sc_metallic: get('sc_metallic'),
    sc_pearl: get('sc_pearl'),
    sc_p2p: get('sc_p2p', false),
    calculation_method: get('calculation_method', ''),
    sc_threshold: get('sc_threshold'),
    weighted_no: get('weighted_no', { oversize: 0, severe: 0, heavy: 0, medium: 0, light: 0 }),
    products: get('products', []),
    parts: get('parts', []),
    prices: get('prices', { dent: {}, scratch: {} }),
  };
}

export default {
  components: {
    VechicleSurcharge: () => import("../clients/components/VehicleSurcharge"),
    DentTable: () => import("../clients/components/DentTable"),
    ProductTable: () => import("../clients/components/productTable"),
    AddPart: () => import("../../quote/components/AddPart"),
    ScratchTable: () => import("../clients/components/ScratchTable"),
  },
  data: (vm) => ({
    clientId: vm.$route.params.clientId,
    model: modelInit(),
    partsList: [],
  }),
  validations() {
    return {
      model: {
        name: { required },
      }
    }
  },
  computed: {
    ...mapGetters(['priceLists']),
  },
  watch: {
    priceLists(newVal) {
      if (newVal) {
        if (this.clientId == 'create') this.model.prices = newVal;
        else {
          this.model.prices.dent = this.model.prices.dent ? { ...newVal.dent, ...this.model.prices.dent } : newVal.dent;
          this.model.prices.scratch = this.model.prices.scratch ? { ...newVal.scratch, ...this.model.prices.scratch } : newVal.scratch;
        }
      }
    },
  },
  created() {
    if (this.clientId != 'create') {
      this.fetchClient();
    }
    this.getPDRLists();
    this.getProductLists();
  },
  methods: {
    ...mapActions(['getPDRLists', 'getClient', 'createClient', 'updateSelectedClient',
      'getProductLists', 'getPartLists']),
    async fetchClient() {
      this.getClient({ id: this.clientId }).then(response => this.model = modelInit(response));

      this.partsList = await this.getPartLists();
    },
    setProduct(ev) {
      const prodIndex = this.model.products.length ? this.model.products.findIndex(item => item.value == ev.value) : -1;
      if (prodIndex !== -1) {
        this.model.products.splice(prodIndex, 1, ev);
      } else {
        this.model.products.push(ev);
      }
    },
    setPart(ev) {
      const partIndex = this.model.parts.indexOf(v);

      if (partIndex === -1) {
        this.model.parts.push(ev);
      } else {
        this.model.parts.splice(partIndex, 1);
      }
    },
    saveClient() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$helpers.focusErrorElement(this.$v.model, this.$refs);
        return;
      }
      if (this.clientId != 'create') {
        this.updateSelectedClient(this.model).then(resp => this.$router.push("/clients"));
      } else {
        this.createClient(this.model).then(resp => this.$router.push("/clients"));
      }
    }
  },
};
</script>
